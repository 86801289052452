import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import Content from './pages/Content';

function App() {
  return (
    <div className="App">
      <Content />
    </div>
  );
}

export default App;
